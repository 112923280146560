import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import combineClasses from 'shared/foreground/utils/combineClasses';
import forwardRef from 'shared/foreground/utils/forwardRef';
import isComposing from 'shared/foreground/utils/isComposing';
import { isHTMLElement } from 'shared/typeValidators';
import { isExtension } from 'shared/utils/environment';
import makeLogger from 'shared/utils/makeLogger';

import useIsFocused from '../hooks/useIsFocused';
import Button from './Button';
import styles from './EditNoteForm.module.css';
import Textarea, { Props as TextareaProps } from './Textarea';

const logger = makeLogger(__filename);

export type Props = {
  extraTextareaProps?: Partial<React.HTMLAttributes<HTMLTextAreaElement>>;
  isFollowingFormFocused?: boolean;
  isShownInMargin?: boolean;
  note?: string;
  onActivityChange?: (isActive: boolean) => void;
  onCancel(): void;
  onHasDirtyTrimmedValueUpdated?: (hasDirtyValue: boolean) => void;
  onSave(note: string): void;
  placeholder?: string;
  requestPopoverHide?: () => void;
  shouldShowIfEmpty?: boolean;
  textareaClassNames?: {
    textarea?: string;
    wrapper?: string;
  };
} & Partial<React.HTMLAttributes<HTMLFormElement>>;

export default React.memo(
  forwardRef<Props, HTMLFormElement>(function EditNoteForm(
    {
      /* eslint-disable @typescript-eslint/no-empty-function */
      className,
      extraTextareaProps,
      isFollowingFormFocused,
      isShownInMargin,
      note = '',
      onActivityChange = () => {},
      onBlur: onBlurArgument,
      onCancel,
      onFocus: onFocusArgument,
      onHasDirtyTrimmedValueUpdated = () => {},
      onSave,
      placeholder = 'Add a note...',
      requestPopoverHide,
      shouldShowIfEmpty = true,
      textareaClassNames,
      /* eslint-enable @typescript-eslint/no-empty-function */
      ...extraProps
    },
    formRef,
  ) {
    const fieldId = `edit-note-input-${Math.random()}`;
    const fieldRef = useRef<HTMLTextAreaElement>(null);

    const [value, setValue] = useState(note);
    useEffect(() => setValue(note), [note, setValue]);

    const { isFocused, ...internalFocusListeners } = useIsFocused();
    const onBlur: React.FocusEventHandler<HTMLFormElement> = useCallback(
      (event) => {
        internalFocusListeners.onBlur(event);
        onBlurArgument?.(event);
      },
      [internalFocusListeners, onBlurArgument],
    );
    const onFocus: React.FocusEventHandler<HTMLFormElement> = useCallback(
      (event) => {
        internalFocusListeners.onFocus(event);
        onFocusArgument?.(event);
      },
      [internalFocusListeners, onFocusArgument],
    );

    const hasDirtyValue = useMemo(() => value !== note, [note, value]);
    const hasDirtyTrimmedValue = useMemo(() => value.trim() !== note.trim(), [note, value]);
    // Let parent know when this has changed
    useEffect(() => {
      onHasDirtyTrimmedValueUpdated(hasDirtyTrimmedValue);
    }, [hasDirtyTrimmedValue, onHasDirtyTrimmedValueUpdated]);
    useEffect(() => {
      onActivityChange(hasDirtyTrimmedValue || isFocused);

      return () => {
        onActivityChange(false);
      };
    }, [hasDirtyTrimmedValue, isFocused, onActivityChange]);

    // When shown in the margin, if the user edits the field and then unfocuses, we want to keep the edit UI visible
    const isInReadOnlyMode = useMemo(() => {
      return !(isShownInMargin && (isFocused || hasDirtyValue));
    }, [hasDirtyValue, isFocused, isShownInMargin]);
    const shouldBeKeptOpen = useMemo(
      () => Boolean(note.trim() && isShownInMargin),
      [note, isShownInMargin],
    );

    const blur = useCallback(() => {
      if (!formRef.current) {
        logger.warn('formRef.current is undefined in blur()');
        return;
      }
      if (isHTMLElement(document.activeElement) && formRef.current.contains(document.activeElement)) {
        document.activeElement.blur();
      }
    }, [formRef]);

    const onFieldInput = useCallback(
      (event: Parameters<NonNullable<TextareaProps['onInput']>>[0]) => {
        const updatedValue = (event.target as HTMLTextAreaElement).value;
        setValue(updatedValue);
      },
      [setValue],
    );

    const cancel = useCallback(() => {
      setValue(note);
      if (shouldBeKeptOpen || !requestPopoverHide) {
        blur();
      } else {
        requestPopoverHide();
      }
      onCancel();
    }, [blur, note, onCancel, requestPopoverHide, setValue, shouldBeKeptOpen]);

    const save = useCallback(() => {
      if (!fieldRef.current) {
        return;
      }
      onSave(fieldRef.current.value);
      if (!fieldRef.current.value.trim()) {
        setValue('');
      }
      if (!isShownInMargin || !fieldRef.current.value.trim()) {
        requestPopoverHide?.();
        return;
      }
      blur();
    }, [blur, onSave, isShownInMargin, requestPopoverHide, setValue]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
      (event) => {
        if (!fieldRef.current) {
          return;
        }
        event.preventDefault();
        save();
      },
      [fieldRef, save],
    );

    const onKeyDownCapture: React.KeyboardEventHandler<HTMLTextAreaElement> = useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (isComposing(event)) {
          return;
        }

        if (isExtension) {
          event.stopPropagation();
        }

        // Enter submits, shift+enter inserts newline :/
        if (event.key === 'Enter') {
          if (event.shiftKey) {
            return;
          }

          event.preventDefault();
          save();
          return;
        }

        if (event.key === 'Escape') {
          cancel();
        }
      },
      [cancel, save],
    );

    const rootClasses = [styles.root, className];
    if (isShownInMargin) {
      rootClasses.push(styles.rootShownInMargin);
    } else {
      rootClasses.push(styles.rootShownInline);
    }

    if (isInReadOnlyMode) {
      rootClasses.push(styles.rootInReadOnlyMode);
    }

    if (isFollowingFormFocused) {
      rootClasses.push(styles.rootWhenFollowingFormIsActive);
    }

    // We shouldn't need to check the actual value but Safari didn't fire the submit handler without it
    if (!shouldShowIfEmpty && !note && !(value || fieldRef.current?.value.trim()) && !isFocused) {
      rootClasses.push('hideAccessibly');
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const textareaProps: TextareaProps & { 'data-gramm'?: 'true' | 'false'; } = {
      ...extraTextareaProps,
      classNames: {
        textarea: combineClasses(styles.field, textareaClassNames?.textarea),
        wrapper: combineClasses(styles.fieldWrapper, textareaClassNames?.wrapper),
      },
      cols: 1,
      id: fieldId,
      onInput: onFieldInput,
      onKeyDown: onKeyDownCapture,
      placeholder,
      rows: 1,
      value,
    };

    if (isShownInMargin && isInReadOnlyMode) {
      textareaProps.autoCorrect = 'off';
      textareaProps.spellCheck = 'false';
      textareaProps['data-gramm'] = 'false';
    }

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          {...extraProps}
          className={rootClasses.join(' ')}
          onBlur={onBlur}
          onFocus={onFocus}
          onSubmit={onSubmit}
          ref={formRef}
        >
          <label className="hideAccessibly" htmlFor={fieldId}>
            Note
          </label>

          <Textarea ref={fieldRef} {...textareaProps} />

          <fieldset className={styles.buttonsContainer}>
            <Button onClick={cancel} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </fieldset>
        </form>
      </>
    );
  }),
);
