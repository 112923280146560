
import {
  type DocumentChunk,
  type DocumentChunkContent,
  DocumentChunkType } from '../../types/chunkedDocuments';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import { arrayBufferToUTF8String, base64ToArrayBuffer } from './bytes';
import { decryptData } from './drm';

export async function unpackChunk(chunk: DocumentChunk): Promise<DocumentChunkContent | undefined> {
  const data = base64ToArrayBuffer(chunk.data);
  if (!chunk.is_encrypted) {
    return decodeChunkContent(chunk, data);
  }
  if (!chunk.password) {
    exceptionHandler.captureException('Chunk is encrypted but no password', {
      extra: {
        chunk,
      },
    });
    return undefined;
  }
  const decryptedData = await decryptData(data, chunk.password);
  return decodeChunkContent(chunk, decryptedData);
}

function decodeChunkContent(chunk: DocumentChunk, data: ArrayBuffer): DocumentChunkContent | undefined {
  switch (chunk.type) {
    case DocumentChunkType.Style:
    case DocumentChunkType.Script:
    case DocumentChunkType.Navigation:
    case DocumentChunkType.Document:
    case DocumentChunkType.Smil:
      return {
        type: chunk.type,
        id: chunk.internal_id,
        text: arrayBufferToUTF8String(data),
        data: undefined,
      };
    case DocumentChunkType.Image:
    case DocumentChunkType.Unknown:
    case DocumentChunkType.Vector:
    case DocumentChunkType.Font:
    case DocumentChunkType.Video:
    case DocumentChunkType.Audio:
    case DocumentChunkType.Cover:
      return {
        type: chunk.type,
        id: chunk.internal_id,
        text: undefined,
        data,
      };
  }
}
