import { useCallback } from 'react';
import useGlobalStateWithFallback from 'shared/foreground/utils/useGlobalStateWithFallback';

import convertVhToPxSafe from '../utils/convertVhToPxSafe';

export const useYouTubePlayerHeight = () => {
  return useGlobalStateWithFallback(
    convertVhToPxSafe(50),
    useCallback((state) => state.client.youtube.playerHeight, []),
  );
};
