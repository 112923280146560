import type { DocumentChunkContent, DocumentChunkMapByFilename } from '../../types/chunkedDocuments';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import { unpackChunk } from './unpackChunk';

export async function unpackChunkWithFilename(
  absoluteFilename: string,
  chunksByFilename: DocumentChunkMapByFilename,
): Promise<DocumentChunkContent | undefined> {
  const chunk = chunksByFilename[absoluteFilename];

  if (!chunk) {
    exceptionHandler.captureException('Could not find file chunk', {
      extra: {
        absoluteFilename,
      },
    });
    return undefined;
  }

  return unpackChunk(chunk);
}

/**
 * Resolves a relative path (e.g. an `<a>` href) against a source file path to produce a path relative to the root of the epub archive.
 * @param sourcePath - The source file path (e.g., 'OEBPS/html/chapter1.html')
 * @param relativePath - The relative path to resolve (e.g., '../images/image2.png')
 * @returns Path relative to the root of the epub archive without leading slash (e.g., 'OEBPS/images/image2.png'). Should correspond to chunk `filename`s.
 */
export function resolveRelativePath(sourcePath: string, relativePath: string): string {
  if (relativePath.startsWith('/')) {
    return relativePath.replace(/^\//, '');
  }

  const sourceSegments = sourcePath.split('/');
  sourceSegments.pop();
  const relativeSegments = relativePath.split('/');

  if (!relativePath.startsWith('./') && !relativePath.startsWith('../')) {
    return [...sourceSegments, ...relativeSegments].join('/');
  }

  for (const segment of relativeSegments) {
    if (segment === '.' || segment === '') {
      continue;
    } else if (segment === '..') {
      sourceSegments.pop();
    } else {
      sourceSegments.push(segment);
    }
  }

  return sourceSegments.join('/');
}
