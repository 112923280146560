import { Category } from '../../../../types';
import nowTimestamp from '../../../../utils/dates/nowTimestamp';
import { StateUpdateOptionsWithoutEventName } from '../../../types';
import { updateDocument } from './update';

export const toggleEpubOriginalStyles = async (
  documentId: string,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateDocument(
    documentId,
    (doc) => {
      if (!doc?.source_specific_data?.epub?.originalStylesEnabled) {
        doc.source_specific_data = {
          ...doc?.source_specific_data ?? {},
          epub: {
            ...doc?.source_specific_data?.epub ?? {},
            originalStylesEnabled: true,
          },
        };
      } else {
        doc.source_specific_data.epub.originalStylesEnabled = false;
      }
    },
    { ...options, eventName: 'epub-original-styles-toggled' },
  );
};

export const setAreEpubOriginalStylesEnabled = async (
  documentId: string,
  value: boolean,
  options: StateUpdateOptionsWithoutEventName,
) => {
  await updateDocument(
    documentId,
    (doc) => {
      if (!doc?.source_specific_data?.epub?.originalStylesEnabled) {
        doc.source_specific_data = {
          ...doc?.source_specific_data ?? {},
          epub: {
            ...doc?.source_specific_data?.epub ?? {},
            originalStylesEnabled: value,
          },
        };
      } else {
        doc.source_specific_data.epub.originalStylesEnabled = value;
      }
    },
    { ...options, eventName: 'epub-original-styles-set' },
  );
};

export const toggleDocumentFinished = async (docId: string): Promise<void> => {
  await updateDocument(docId, (doc) => {
    if (doc.category !== Category.EPUB) {
      throw new Error('Document is not an EPUB');
    }

    if (doc.source_specific_data?.epub?.finished_at) {
      delete doc.source_specific_data.epub.finished_at;
    } else {
      if (!doc.source_specific_data) {
        doc.source_specific_data = {};
      }

      if (!doc.source_specific_data.epub) {
        doc.source_specific_data.epub = {};
      }

      doc.source_specific_data.epub.finished_at = nowTimestamp();
    }
  }, { userInteraction: 'unknown', eventName: 'document-finished', isUndoable: false });
};
