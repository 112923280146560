import { Core } from '@pdftron/webviewer';
import debounce from 'lodash/debounce';
import React, { type ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { globalState } from 'shared/foreground/models';
import { usePersistentPdfSettings } from 'shared/foreground/stateHooks';
import forwardRef from 'shared/foreground/utils/forwardRef';
import {
  AnyDocument,
  DisplayTheme,
  PdfsThumbnailPromise,
  PdfsThumbnailsCache,
  SidebarContentType,
} from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { getAdjacentDocumentIdUsingIds } from 'shared/utils/getAdjacentDocumentId';

import { AdaptiveHeaderContext } from '../contexts';
import { useIsLeftSidebarHidden } from '../hooks/hooks';
import getNumericCssPropertyValue from '../utils/getNumericCssPropertyValue';
import { getReadingUrlForDocument } from '../utils/getReadingUrlForDocument';
import { isReaderViewUrl } from '../utils/pathnameHelpers';
import { useShortcutsMap } from '../utils/shortcuts';
import { HeaderSeparator } from './AdaptiveHeader';
import styles from './DocumentReader/DocumentReader.module.css'; // TODO: extract out
import SidebarContentTypeDropdown from './Dropdown/SidebarContentTypeDropdown';
import FakeSuspense from './FakeSuspense';
import ArrowLeftIcon from './icons/ArrowLeftIcon';
import ChevronDownIcon from './icons/ChevronDownIcon';
import ChevronUpIcon from './icons/ChevronUpIcon';
import PdfBookmarks from './PdfBookmarks';
import { PdfTronContext } from './PDFViewer';
import Tooltip from './Tooltip';

const THUMBNAILS_HEIGHT = 211;
const THUMBNAILS_LIST_VERTICAL_PADDING_SIZE = 24;
export const thumbnailsCache: PdfsThumbnailsCache = {};
type AsideProps = {
  currentDoc?: AnyDocument | null | undefined;
  documentPathPrefix?: string;
  inboxDocumentIds?: AnyDocument['id'][];
  parentPath: string;
  sidebarsHidden: boolean;
  // TODO: for some reason ESLint flags this as unused, which is patently false.
  // eslint-disable-next-line react/no-unused-prop-types
  tableOfContents?: JSX.Element | null;
};

export function NotebookAside({
  currentDoc,
  documentPathPrefix,
  parentPath,
  sidebarsHidden,
  tableOfContents,
}: Omit<AsideProps, 'inboxDocumentIds'>): ReactElement {
  return (
    <>
      <InboxNav
        {...{
          className: `${sidebarsHidden ? '' : styles.fullWidth}`,
          currentDoc,
          documentPathPrefix,
          parentPath,
          isNotebookView: true,
        }}
      />

      <aside className={`${styles.aside} ${sidebarsHidden ? styles.sidebarsHidden : ''}`}>
        <div className={styles.tocWrapper}>{tableOfContents}</div>
      </aside>
    </>
  );
}

export const ArticleAside = ({
  currentDoc,
  documentPathPrefix,
  inboxDocumentIds,
  parentPath,
  sidebarsHidden,
  tableOfContents,
}: AsideProps) => {
  return (
    <>
      <InboxNav
        {...{
          className: `${sidebarsHidden ? '' : styles.fullWidth}`,
          currentDoc,
          documentPathPrefix,
          inboxDocumentIds,
          parentPath,
        }}
      />

      <aside className={`${styles.aside} ${sidebarsHidden ? styles.sidebarsHidden : ''}`}>
        <div className={styles.tocWrapper}>{tableOfContents}</div>
      </aside>
    </>
  );
};
const PdfThumbnail = ({
  isActive,
  pageNumber,
  thumbnailSrc,
  goToNewPageNumber,
  invertColors,
  style,
}: {
  isActive: boolean;
  pageNumber: number;
  thumbnailSrc: string;
  style: React.CSSProperties;
  goToNewPageNumber: (thumbnailPage: number) => void;
  invertColors: boolean;
}) => {
  const onClick = useCallback(() => {
    goToNewPageNumber(pageNumber);
  }, [goToNewPageNumber, pageNumber]);

  return (
    <div style={style} className={styles.thumbnailWrapper}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        data-thumbnail-page={pageNumber}
        onClick={onClick}
        className={`${styles.thumbnail} ${isActive ? styles.activeThumbnail : ''} ${
          invertColors ? styles.invertColors : ''
        }`}
      >
        {thumbnailSrc ? <img alt="" src={thumbnailSrc} /> : <div className={styles.imageLoading} />}
        <div className={styles.pageNumber}>{pageNumber}</div>
      </div>
    </div>
  );
};
// We initial add a delay to the loading of the thumbnails to make sure PDFTron
// first renders the PDF content. Otherwise, it will render it after all the thumbnails are done.
const PDFTHumbnailsLoading = ({
  totalPageCount,
  invertColors,
  asideHeight,
}: {
  totalPageCount: number;
  invertColors: boolean;
  asideHeight: number;
}) => {
  const defaultAmountToShow =
    Math.floor((asideHeight - THUMBNAILS_LIST_VERTICAL_PADDING_SIZE * 2) / THUMBNAILS_HEIGHT) + 1;
  const amountToShow = totalPageCount < defaultAmountToShow ? totalPageCount : defaultAmountToShow;
  const classes = ['listRoot', 'has-visible-scrollbar', styles.thumbnailsWrapper];

  return (
    <div
      style={{ padding: `${THUMBNAILS_LIST_VERTICAL_PADDING_SIZE}px 0` }}
      className={classes.join(' ')}
    >
      {Array.from({ length: amountToShow }, (_, index) => {
        return (
          <PdfThumbnail
            key={index}
            style={{}}
            isActive={false}
            goToNewPageNumber={() => null}
            pageNumber={index + 1}
            thumbnailSrc=""
            invertColors={invertColors}
          />
        );
      })}
    </div>
  );
};
type VirtualizedListRef = React.RefObject<
  FixedSizeList<{
    scrollTo: (offset: number) => void;
  }> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any;
  }
>;
type VirtualizedListData = {
  docId: string;
  invertColors: boolean;
  leftSidebarHidden: boolean;
  currentPageNumber?: number;
  virtualizedListRef: VirtualizedListRef;
  documentViewer?: Core.DocumentViewer;
  setCurrentPageNumber: (pageNumber: number) => void;
};
const VirtualizedThumbnail = React.memo(function VirtualizedThumbnail({
  index,
  style,
  data,
}: {
  index: number;
  style: React.CSSProperties;
  data: VirtualizedListData;
}) {
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const pageNumber = index + 1;
  const docId = data.docId;
  const isActive = pageNumber === data.currentPageNumber;
  const invertColors = data.invertColors;
  const documentViewer = data.documentViewer;
  const setCurrentPageNumber = data.setCurrentPageNumber;

  const goToNewPageNumber = useCallback(
    (thumbnailPage: number) => {
      if (!documentViewer) {
        return;
      }

      documentViewer.setCurrentPage(thumbnailPage, false);

      // Waiting for the pageNumberUpdated event to fire was a bit slow.
      // So, manually setting the page to make the UI feel more responsive.
      setCurrentPageNumber(thumbnailPage);
    },
    [documentViewer, setCurrentPageNumber],
  );

  useEffect(() => {
    async function loadThumbnailSrc() {
      if (!documentViewer || thumbnailSrc) {
        return;
      }

      const doc = documentViewer.getDocument();

      if (!doc) {
        return;
      }

      let thumbnailPromise = (thumbnailsCache[docId] || {})[pageNumber];

      if (!thumbnailPromise) {
        thumbnailPromise = new Promise<PdfsThumbnailPromise>((resolve) => {
          doc.loadThumbnail(pageNumber, (thumbnail: HTMLImageElement | HTMLCanvasElement) => {
            // In some weird cases thumbnail is an img element instead of canvas
            const src: string = (thumbnail as HTMLImageElement).src ?? (thumbnail as HTMLCanvasElement).toDataURL();
            resolve({ pageNumber, src });
          });
        });

        thumbnailsCache[docId] = {
          ...thumbnailsCache[docId] || {},
          [pageNumber]: thumbnailPromise,
        };
      }

      const { src } = await thumbnailPromise;
      setThumbnailSrc(src);
    }

    loadThumbnailSrc();
  }, [docId, documentViewer, pageNumber, thumbnailSrc]);

  return (
    <PdfThumbnail
      style={{
        ...style,
        top: `${parseFloat((style.top ?? 0).toString()) + THUMBNAILS_LIST_VERTICAL_PADDING_SIZE}px`,
      }}
      isActive={isActive}
      goToNewPageNumber={goToNewPageNumber}
      pageNumber={pageNumber}
      thumbnailSrc={thumbnailSrc}
      invertColors={invertColors}
    />
  );
});
const PDFThumbnails = ({
  docId,
  totalPageCount,
  invertColors,
  leftSidebarHidden = false,
  asideHeight,
}: {
  docId: string;
  totalPageCount?: number;
  invertColors: boolean;
  leftSidebarHidden: boolean;
  asideHeight: number;
}) => {
  const virtualizedListRef = useRef<VirtualizedListRef['current']>(null);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>();
  const { documentViewer, documentLoaded } = useContext(PdfTronContext);
  const classes = ['listRoot', 'has-visible-scrollbar', styles.thumbnailsWrapper];

  useEffect(() => {
    if (!documentLoaded || !documentViewer) {
      return;
    }

    setCurrentPageNumber(documentViewer.getCurrentPage());
  }, [documentLoaded, documentViewer]);

  const onPageNumberChanged = useCallback((newPageNumber: number) => {
    setCurrentPageNumber(newPageNumber);
  }, []);

  const debouncedOnPageNumberChanged = debounce(onPageNumberChanged, 300);

  useEffect(() => {
    if (!documentViewer) {
      return;
    }

    documentViewer.addEventListener('pageNumberUpdated', debouncedOnPageNumberChanged);

    return () => {
      documentViewer.removeEventListener('pageNumberUpdated', debouncedOnPageNumberChanged);
    };
  }, [documentViewer, debouncedOnPageNumberChanged]);

  useEffect(() => {
    if (!currentPageNumber || !virtualizedListRef.current) {
      return;
    }

    const itemIndex = currentPageNumber - 1;
    virtualizedListRef.current.scrollToItem(itemIndex, 'smart');
  }, [currentPageNumber]);

  if (!totalPageCount) {
    return null;
  }

  return (
    <FixedSizeList
      className={classes.join(' ')}
      height={asideHeight}
      itemCount={totalPageCount}
      itemData={{
        virtualizedListRef,
        currentPageNumber,
        setCurrentPageNumber,
        invertColors,
        leftSidebarHidden,
        docId,
        documentViewer,
      }}
      itemKey={(index: number) => index}
      itemSize={THUMBNAILS_HEIGHT}
      overscanCount={3}
      ref={virtualizedListRef as React.RefObject<FixedSizeList<VirtualizedListData>>}
      innerElementType={innerElementType}
      width="100%"
    >
      {VirtualizedThumbnail}
    </FixedSizeList>
  );
};
// Need to do this to add padding to the top and bottom of the list
// https://github.com/bvaughn/react-window#can-i-add-padding-to-the-top-and-bottom-of-a-list
const innerElementType = forwardRef<{ style: React.CSSProperties; }, HTMLDivElement>(
  ({ style, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        style={{
          ...style,
          height: `${
            parseFloat((style.height ?? 0).toString()) + THUMBNAILS_LIST_VERTICAL_PADDING_SIZE * 2
          }px`,
        }}
        {...rest}
      />
    );
  },
);
export const PDFAside = ({
  currentDoc,
  documentPathPrefix,
  inboxDocumentIds,
  onBookmarkClicked,
  parentPath,
  pdfBookmarks,
  sidebarsHidden,
  leftSidebarHidden = false,
}: Omit<AsideProps, 'tableOfContents'> & {
  pdfBookmarks: Core.Bookmark[];
  onBookmarkClicked: (pageNum: number) => void;
  leftSidebarHidden: boolean;
}) => {
  const [height, setHeight] = useState(0);
  const asideElementRef = useRef<HTMLDivElement>(null);
  const pdfSettings = usePersistentPdfSettings(currentDoc ? currentDoc.id : '');
  const sidebarContentType = pdfSettings.sidebarContentType;
  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );
  const shouldInvertPDFColors = globalState(
    useCallback((state) => state.client.shouldInvertPDFColors, []),
  );
  const invertColors = isDarkMode && shouldInvertPDFColors;
  const showThumbnails = currentDoc && sidebarContentType === SidebarContentType.Thumbnails;
  const [totalPageCount, setTotalPageCount] = useState<number>();
  const { documentViewer, documentLoaded } = useContext(PdfTronContext);

  const getAsideHeight = useCallback(() => {
    const element = asideElementRef.current;

    if (!element) {
      return 0;
    }

    return element.clientHeight - getNumericCssPropertyValue('--sidebar-nav-height') - 1;
  }, []);

  useEffect(() => {
    const element = asideElementRef.current;

    if (!element) {
      return;
    }

    const height = getAsideHeight();
    setHeight(height);

    const onResize = debounce(() => {
      const height = getAsideHeight();
      setHeight(height);
    }, 300);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [asideElementRef, getAsideHeight, setHeight]);

  useEffect(() => {
    if (!documentViewer) {
      return;
    }

    if (documentLoaded) {
      setTotalPageCount(documentViewer.getPageCount());
    } else {
      setTotalPageCount(undefined);
    }
  }, [documentViewer, documentLoaded]);

  return (
    <>
      <InboxNav
        {...{
          className: `${sidebarsHidden ? '' : styles.fullWidth}`,
          currentDoc,
          documentPathPrefix,
          inboxDocumentIds,
          parentPath,
          isPDFView: true,
        }}
      />

      <aside
        ref={asideElementRef}
        className={`${styles.aside} ${sidebarsHidden ? styles.sidebarsHidden : ''}`}
      >
        {showThumbnails
          ? documentLoaded && totalPageCount
            ? <FakeSuspense
                delay={1000}
                fallback={
                <PDFTHumbnailsLoading
                  totalPageCount={totalPageCount}
                  asideHeight={height}
                  invertColors={invertColors}
                />
              }
            >
              <PDFThumbnails
                docId={currentDoc.id}
                totalPageCount={totalPageCount}
                asideHeight={height}
                invertColors={invertColors}
                leftSidebarHidden={leftSidebarHidden}
              />
            </FakeSuspense>
           : null
         : <div className={styles.tocWrapper}>
            <PdfBookmarks bookmarks={pdfBookmarks} onBookmarkClicked={onBookmarkClicked} />
          </div>
        }
      </aside>
    </>
  );
};
type InboxNavProps = {
  className?: string;
  currentDoc?: AnyDocument | null | undefined;
  documentPathPrefix?: string;
  inboxDocumentIds?: AnyDocument['id'][];
  parentPath: string;
  isPDFView?: boolean;
  isNotebookView?: boolean;
};
export const InboxNav = ({
  className = '',
  currentDoc,
  documentPathPrefix,
  inboxDocumentIds,
  parentPath,
  isPDFView = false,
  isNotebookView = false,
}: InboxNavProps) => {
  const { headerIsHidden } = useContext(AdaptiveHeaderContext);
  const shortcutsMap = useShortcutsMap();
  const leftSidebarHidden = useIsLeftSidebarHidden();

  const previousDocumentId =
    !isNotebookView && currentDoc && inboxDocumentIds
      ? getAdjacentDocumentIdUsingIds({
        currentDocumentId: currentDoc.id,
        documentIds: inboxDocumentIds,
        offset: -1,
      })
      : null;
  const previousDocumentLink = !isNotebookView &&
    <Tooltip content="Previous document" shortcut={shortcutsMap[ShortcutId.GoToPreviousDocument]}>
      <Link
        className={`${previousDocumentId ? '' : styles.disabled}`}
        id="inbox-nav__previous"
        to={() => getReadingUrlForDocument({ documentPathPrefix, docId: previousDocumentId })}
      >
        <ChevronUpIcon text="To previous document" />
      </Link>
    </Tooltip>;
  const nextDocumentId =
    !isNotebookView && currentDoc && inboxDocumentIds
      ? getAdjacentDocumentIdUsingIds({
        currentDocumentId: currentDoc.id,
        documentIds: inboxDocumentIds,
        offset: 1,
      })
      : null;
  const nextDocumentLink = !isNotebookView &&
    <Tooltip content="Next document" shortcut={shortcutsMap[ShortcutId.GoToNextDocument]}>
      <Link
        className={`${nextDocumentId ? '' : styles.disabled}`}
        id="inbox-nav__next"
        to={() => getReadingUrlForDocument({ documentPathPrefix, docId: nextDocumentId })}
      >
        <ChevronDownIcon text="To next document" />
      </Link>
    </Tooltip>;
  const linksToParentDocument = isNotebookView && isReaderViewUrl(parentPath);
  return (
    <nav
      className={`${styles.inboxNav} ${leftSidebarHidden ? styles.leftSidebarHidden : ''} ${
        headerIsHidden ? styles.headerIsHidden : ''
      } ${className}`}
    >
      <Tooltip
        content={linksToParentDocument ? 'Back to parent document' : 'Back to list'}
        shortcut={shortcutsMap[ShortcutId.Esc]}
      >
        <Link
          id="inbox-nav__inbox"
          className={styles.backButton}
          to={{ pathname: parentPath, state: { didNavigateUsingUIBackButton: true } }}
        >
          <ArrowLeftIcon text={linksToParentDocument ? 'To parent document' : 'To inbox'} />
        </Link>
      </Tooltip>
      {!isNotebookView &&
        <div className={styles.navigationButtons}>
          {previousDocumentLink}
          {nextDocumentLink}
        </div>
      }

      {(currentDoc || isNotebookView) &&
        <SidebarContentTypeDropdown docId={currentDoc?.id} showChevron={isPDFView} /> ||
        null}

      {leftSidebarHidden && <HeaderSeparator className={styles.headerSeparator} />}
    </nav>
  );
};
