import create, { GetState, SetState, StoreApi } from 'zustand';
import { NamedSet, StoreApiWithSubscribeWithSelector, subscribeWithSelector } from 'zustand/middleware';

import produce from '../../utils/immer';
import { CurrentlyReadingDocument } from './types';

export type NavigationState = {
  currentlyReadingDocument: CurrentlyReadingDocument | null;
  currentlyReadingId: string | null;
  highlightIdToScrollTo: string | null;
  didNavigateFromExternalApp: boolean;
  update: (updater: (state: NavigationState) => void) => void;
};

type GetInitialDocumentReaderNavigationStateProps = (
  set: NamedSet<NavigationState>,
  get: GetState<NavigationState>,
  api: StoreApi<NavigationState>,
) => NavigationState;
const getInitialDocumentReaderNavigationState = (
  ...zustandArguments: Parameters<GetInitialDocumentReaderNavigationStateProps>
): ReturnType<GetInitialDocumentReaderNavigationStateProps> => {
  const [set, get] = zustandArguments;
  const update: NavigationState['update'] = async (updater) => {
    const lastState = get();
    // Produce the nextState of zustand using immer's produce function:
    const nextState = produce(lastState, (draft) => {
      updater(draft);
    });
    set(() => {
      return nextState;
    }, undefined);
  };
  return {
    update,
    currentlyReadingDocument: null,
    currentlyReadingId: null,
    highlightIdToScrollTo: null,
    didNavigateFromExternalApp: false,
  };
};
export const documentReaderNavigationState = create<
  NavigationState,
  SetState<NavigationState>,
  GetState<NavigationState>,
  StoreApiWithSubscribeWithSelector<NavigationState>
>(subscribeWithSelector((...args) => getInitialDocumentReaderNavigationState(...args)));

export const updateDocumentReaderNavigationState = documentReaderNavigationState.getState().update;
