import sum from 'lodash/sum';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { documentReaderNavigationState } from '../../mobile-library/ReaderContainer/navigationState';
import type { FirstClassDocument, LenientReadingPosition, ReadingPosition } from '../../types';
import makeLogger from '../../utils/makeLogger';
import {
  updateReadingPosition,
  updateScrollPosition,
} from '../stateUpdaters/persistentStateUpdaters/documents/progressRelated';

export type UpdateReadingProgress = (
  pos: ReadingPosition,
  onlyUpdateScrollPosition?: boolean,
) => void;

type TrackWords = {
  track: () => number;
  updateReadingProgress: UpdateReadingProgress;
};

const logger = makeLogger(__filename, { shouldLog: false });

type WordsRead = number;

const getWordsPerMinuteCalculator = (initialWordsRead: number) => {
  // Track last 30 seconds of reading (100 samples at 300ms intervals)
  const wordHistory = new Array(100).fill(0);
  let currentIndex = 0;
  let lastWordsRead = initialWordsRead;

  const calculate = (
    currentWordsRead: WordsRead,
    wpmThreshold: number,
    updateSkimmingTimer: (val: number) => void,
  ) => {
    // Store words read in this 300ms interval in our circular buffer
    wordHistory[currentIndex] = currentWordsRead - lastWordsRead;
    lastWordsRead = currentWordsRead;

    currentIndex = (currentIndex + 1) % 100;

    // Calculate words read since last check using absolute positions
    const wordsReadInSlidingWindow = sum(wordHistory);

    // Convert words per 30 seconds to words per minute
    // Multiply by 2 since 60/30 = 2 (exact conversion from 30 seconds to 1 minute)
    const currentWpm = Math.abs(wordsReadInSlidingWindow * 2);

    // Calculate how long until WPM would drop below reading threshold if user stopped now
    // First get words per second rate from our 30-second sample
    const wordsPerSecond = Math.abs(wordsReadInSlidingWindow / 30);

    // If we're above threshold, calculate time to drop to threshold
    // - (currentWpm - wpmThreshold) is how far above threshold we are
    // - Divide by (wordsPerSecond * 2) to convert back to same WPM units
    // - This gives us seconds until we cross the threshold
    const secondsUntilReading = currentWpm > wpmThreshold
      ? (currentWpm - wpmThreshold) / (wordsPerSecond * 2)
      : 0;

    updateSkimmingTimer(secondsUntilReading);

    return currentWpm;
  };

  return calculate;
};

let updateReadingPositionTimer: number;
let updateScrollPositionTimer: number;
export const trackWordsPerMinute = ({
  docId,
  initialReadingPosition,
  maybeTransformPosition,
  wpmThreshold,
  isReadingProgressTrackingEnabled,
  updateSkimmingTimer,
  setIsUserReading,
  progressUpdateDelay = 2000,
  totalWordsInDocument,
  initialWordsRead,
}: {
  docId: FirstClassDocument['id'] | undefined;
  initialReadingPosition: LenientReadingPosition | null;
  maybeTransformPosition: (position: ReadingPosition) => Promise<ReadingPosition | undefined>;
  wpmThreshold: number;
  isReadingProgressTrackingEnabled: boolean;
  updateSkimmingTimer: (val: number) => void;
  setIsUserReading: (val: boolean) => void;
  progressUpdateDelay?: number;
  totalWordsInDocument: number;
  initialWordsRead: number;
}): TrackWords => {
  logger.debug(`Initializing reading progress tracking, isEnabled: ${isReadingProgressTrackingEnabled}`);
  let isReading = Boolean(isReadingProgressTrackingEnabled);
  setIsUserReading(isReading);

  let _internalReadingPosition: ReadingPosition;
  let _internalWordsRead = initialWordsRead;

  // Use this ID to track latest async call for either update function
  let currentUpdateScrollPositionCallbackId = 0;
  let currentUpdateReadingPositionCallbackId = 0;

  const updateInternalReadingPosition = (newPos: ReadingPosition) => {
    _internalReadingPosition = newPos;
  };

  const calculateWpm = getWordsPerMinuteCalculator(initialWordsRead);

  const track = () => {
    // This function tracks the seen WPM and decides if we are in skimming or reading mode
    // If we pass a certain threshold of WPM in a specific time, we trigger skimming
    // if that value reduces to a "reading" threshold, we switch to reading
    return setInterval(() => {
      const wordsRead = _internalWordsRead;

      const wordsPerMinute = calculateWpm(
        wordsRead,
        wpmThreshold,
        updateSkimmingTimer,
      );
      if (isReadingProgressTrackingEnabled) {
        logger.debug(`wpm: ${wordsPerMinute}`);
        if (wordsPerMinute > wpmThreshold) {
          isReading = false;
          setIsUserReading(false);
        } else if (!isReading) {
          // Triggered when we switch from skimming back to reading
          isReading = true;
          setIsUserReading(true);
          currentUpdateReadingPositionCallbackId += 1;
          currentUpdateScrollPositionCallbackId += 1;
          updateReadingPositionCallback(_internalReadingPosition, false);
        }
      }
    }, 300) as unknown as number;
  };

  const updateScrollPositionCallback = async (newPosition: ReadingPosition) => {
    if (!docId) {
      return;
    }
    const currentId = currentUpdateScrollPositionCallbackId;
    // This function allows the user of the useReadingProgressTracking hook to change/add data to the scroll position
    // right before a DB write
    const readingPosition = await maybeTransformPosition(newPosition);
    // Check for outdated calls caused by slow getReadingPosition execution
    if (currentId !== currentUpdateScrollPositionCallbackId || !readingPosition) {
      return;
    }

    await updateScrollPosition(docId, readingPosition, {
      errorMessageIfNothingFound: false,
      eventName: 'document-scroll-position-updated',
      userInteraction: 'scroll',
      isUndoable: false,
    });
  };
  const updateReadingPositionCallback = async (newPosition: ReadingPosition, allowExperienceGain: boolean) => {
    if (!docId) {
      return;
    }
    const currentId = currentUpdateReadingPositionCallbackId;
    // This function allows the user of the useReadingProgressTracking hook to change/add data to the scroll position
    // right before a DB write
    const readingPosition = await maybeTransformPosition(newPosition);
    // Check for outdated calls caused by slow getReadingPosition execution
    if (currentId !== currentUpdateReadingPositionCallbackId || !readingPosition) {
      return;
    }

    if (isReading && isReadingProgressTrackingEnabled) {
      logger.debug(`Updating reading position to pos ${readingPosition.serializedPosition}`);
      await updateReadingPosition(docId, readingPosition, {
        force: true,
        errorMessageIfNothingFound: false,
        eventName: 'document-progress-position-updated',
        isUndoable: false,
        userInteraction: 'scroll',
        allowExperienceGain,
      });
    }
  };

  /*
    This is the meat of this hook
    This function is returned from the useReadingProgressTracking hook and is called whenever the
    caller wants to update reading progress (like a scroll event just ended)

    Within here, we update the scroll position first, and then the reading position.
    the reading position is delayed a bit to make sure we truly are reading and not skimming

    The updatePosition callbacks both utilize a function called getReadingPosition, where they
   */
  const updateReadingProgress: UpdateReadingProgress = (
    newReadingPosition: ReadingPosition,
    onlyUpdateScrollPosition = false,
  ) => {
    updateInternalReadingPosition(newReadingPosition);

    _internalWordsRead = newReadingPosition.scrollDepth * totalWordsInDocument;

    if (updateReadingPositionTimer) {
      clearTimeout(updateReadingPositionTimer);
    }
    if (updateScrollPositionTimer) {
      clearTimeout(updateScrollPositionTimer);
    }
    currentUpdateReadingPositionCallbackId += 1;
    currentUpdateScrollPositionCallbackId += 1;
    if (newReadingPosition) {
      // Scroll position can be updated much faster but due to server load, we throttle this for now
      updateScrollPositionTimer = setTimeout(
        () => updateScrollPositionCallback(newReadingPosition),
        progressUpdateDelay,
      ) as unknown as number;
      // Set the timeout to 2000ms to allow a bit of buffer for user
      // to enter skimming mode without accidentally updating reading progress too early
      const lastSavedReadingPosition = documentReaderNavigationState.getState().currentlyReadingDocument?.readingPosition;
      if (newReadingPosition.scrollDepth > (lastSavedReadingPosition?.scrollDepth ?? 0) && !onlyUpdateScrollPosition) {
        updateReadingPositionTimer = setTimeout(
        () => updateReadingPositionCallback(newReadingPosition, true),
        progressUpdateDelay,
      ) as unknown as number;
      }
    }
  };

  return {
    track,
    updateReadingProgress,
  };
};

export const useReadingProgressTracking = ({
  docId,
  initialReadingPosition,
  transformReadingPosition,
  wpmThreshold,
  isEnabled = true,
  updateSkimmingTimer,
  progressUpdateDelay = 2000,
  totalWordsInDocument,
  initialWordsRead,
}: {
  docId: FirstClassDocument['id'] | undefined;
  initialReadingPosition: LenientReadingPosition | null;
  transformReadingPosition?: (readingPosition: ReadingPosition) => Promise<ReadingPosition | undefined>;
  wpmThreshold: number;
  isEnabled?: boolean;
  updateSkimmingTimer: (val: number) => void;
  progressUpdateDelay?: number;
  totalWordsInDocument: number;
  initialWordsRead: number;
}) => {
  // Keep track of the latest updateProgress function in this ref
  const defaultTransformPositionFunction = useCallback(
    (position: ReadingPosition) => Promise.resolve(position),
    [],
  );
  const [isUserReading, setIsUserReading] = useState(true);

  useEffect(() => {
    logger.info(`Is user reading: ${isUserReading}`);
  }, [isUserReading]);

  const { track, updateReadingProgress } = useMemo(
    () =>
      trackWordsPerMinute({
        docId,
        initialReadingPosition,
        totalWordsInDocument,
        maybeTransformPosition: transformReadingPosition ?? defaultTransformPositionFunction,
        wpmThreshold,
        isReadingProgressTrackingEnabled: isEnabled,
        updateSkimmingTimer,
        setIsUserReading,
        progressUpdateDelay,
        initialWordsRead,
      }),
    [
      docId,
      initialReadingPosition,
      totalWordsInDocument,
      defaultTransformPositionFunction,
      isEnabled,
      transformReadingPosition,
      updateSkimmingTimer,
      progressUpdateDelay,
      wpmThreshold,
      initialWordsRead,
    ],
  );

  useEffect(() => {
    const tracker = track();
    return () => {
      clearInterval(tracker);
      if (updateScrollPositionTimer) {
        clearInterval(updateScrollPositionTimer);
      }
      if (updateReadingPositionTimer) {
        clearInterval(updateReadingPositionTimer);
      }
    };
  }, [track]);
  return { updateReadingProgress, isUserReading };
};
