export function rawStringToArrayBuffer(rawBytesString: string) {
  return Uint8Array.from(rawBytesString, (c) => c.charCodeAt(0)).buffer;
}

export function arrayBufferToRawString(buffer: ArrayBufferLike): string {
  return Array.from(new Uint8Array(buffer), (c) => String.fromCharCode(c)).join('');
}

export function base64Decode(base64string: string): ArrayBuffer {
  return rawStringToArrayBuffer(atob(base64string));
}

export function base64Encode(buffer: ArrayBuffer): string {
  return btoa(arrayBufferToRawString(buffer));
}
