import React, { useCallback, useEffect, useState } from 'react';
import { globalState } from 'shared/foreground/models';
import { isDesktopApp } from 'shared/utils/environment';
import urlJoin from 'shared/utils/urlJoin';

import { openURL } from '../utils/openURL';
import useLocation from '../utils/useLocation';
import Button from './Button';
import styles from './DeepLinkWrapper.module.css';

interface DeepLinkSplashScreenProps {
  setShouldShowDeepLinkSplashScreen: (shouldShowSplashScreen: boolean) => void;
}

const DeepLinkSplashScreen: React.FC<DeepLinkSplashScreenProps> = ({ setShouldShowDeepLinkSplashScreen }) => {
  const { pathname, search } = useLocation();

  const handleRetryDeepLink = useCallback(() => {
    openURL(urlJoin([`wiseread://`, `${pathname}${search}`]));
  }, [pathname, search]);

  const handleContinueToBrowser = () => {
    setShouldShowDeepLinkSplashScreen(false);
  };

  useEffect(() => {
    openURL(urlJoin([`wiseread://`, `${pathname}${search}`]));
  }, [pathname, search]);

  return (
    <div className={styles.container}>
      <img src="/logo-dark-180x180.png" alt="readwise dark logo" className={styles.logo} />
      <h2 className={styles.title}>Redirecting to your Reader app…</h2>
      <div className={styles.buttonContainer}>
        <Button
          variant="unstyled"
          onClick={handleRetryDeepLink}
          className={styles.button}
        >
          If you weren&apos;t redirected, click here.
        </Button>
        <Button
          variant="unstyled"
          onClick={handleContinueToBrowser}
          className={styles.button}
        >
          Continue to the browser version.
        </Button>
      </div>
    </div>
  );
};

interface DeepLinkWrapperProps {
  children: React.ReactNode;
}

const DeepLinkWrapper: React.FC<DeepLinkWrapperProps> = ({ children }) => {
  const shouldOpenReaderLinksInDesktopApp = globalState((state) => state.client.shouldOpenReaderLinksInDesktopApp);
  const [shouldShowDeepLinkSplashScreen, setShouldShowDeepLinkSplashScreen] = useState(shouldOpenReaderLinksInDesktopApp);

  useEffect(() => {
    if (isDesktopApp) {
      setShouldShowDeepLinkSplashScreen(false);
    }
  }, [shouldOpenReaderLinksInDesktopApp]);

  return shouldShowDeepLinkSplashScreen ? <DeepLinkSplashScreen setShouldShowDeepLinkSplashScreen={setShouldShowDeepLinkSplashScreen} /> : <>{children}</>;
};

export default DeepLinkWrapper;
