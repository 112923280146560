import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import background from 'shared/foreground/portalGates/toBackground';
import { saveNewYouTubeDocumentWithCaptionsLanguage } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/create';
import { FirstClassDocument } from 'shared/types';

import { getReadingUrlForDocument } from '../../utils/getReadingUrlForDocument';
import Button from '../Button';
import GlobeIcon from '../icons/24StrokeGlobe';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './DropdownVideoCaptionsLanguage.module.css';

export default function DropdownVideoCaptionsLanguage({ doc, documentPathPrefix }: { doc: FirstClassDocument; documentPathPrefix?: string; }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const captionLanguages = useMemo(() => doc.source_specific_data?.video?.caption_languages, [doc]);

  const options = useMemo(() => {
    if (!captionLanguages) {
      return [{
        type: DropdownOptionType.Node,
        node: <span className={styles.loadingCaptionsNode}>
          <Spinner className={styles.spinner} /> Loading captions...
        </span>,
      }];
    }

    const currentCaptionLanguageCode = doc.source_specific_data?.video?.caption_language_code;

    return Object.entries(captionLanguages).map(([languageCode, name]) => ({
      type: DropdownOptionType.Item,
      name,
      checked: languageCode === currentCaptionLanguageCode,
      onSelect: async () => {
        const { id: docId } = await saveNewYouTubeDocumentWithCaptionsLanguage(doc.url, languageCode);

        if (!docId) {
          return;
        }

        setIsOpen(false);
        history.push(getReadingUrlForDocument({ docId, documentPathPrefix }));
        background.pollLatestState(30);
      },
    })) ?? [];
  }, [doc, history, documentPathPrefix, captionLanguages]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Dropdown
      trigger={
        <Tooltip
          disabled={isOpen}
          content="Captions language"
        >
          <DropdownMenu.Trigger asChild>
            <Button className={styles.triggerElement} variant="unstyled">
              <GlobeIcon />
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
      }
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      appendToDocumentBody
      contentClassName={styles.dropdownContent}
      alignOffset={-10}
    />
  );
}
